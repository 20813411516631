import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteAudioVisualizer, DyteAvatar, DyteNameTag, DyteNetworkIndicator, DyteParticipantTile, } from '@dytesdk/react-ui-kit';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment } from 'react/jsx-runtime';
import InQStyleGuide, { ACTIVE_SPEAKER_COLOUR, RAISED_HAND_COLOUR, } from '../../constants';
import { useLocale, useIsSpeaking } from '../../hooks';
import { HandRaisedIndicator } from '../HandRaisedIndicator/HandRaisedIndicator';
export var NameTagPosition;
(function (NameTagPosition) {
    NameTagPosition["LEFT"] = "left";
    NameTagPosition["CENTER"] = "center";
})(NameTagPosition || (NameTagPosition = {}));
const useStyles = makeStyles((theme) => ({
    tile: {
        position: 'relative',
    },
    handRaised: {
        border: `${theme.spacing(0.375)}px solid ${RAISED_HAND_COLOUR} !important`,
        borderRadius: theme.spacing(2),
    },
    speakerTile: {
        border: `${theme.spacing(0.375)}px solid  ${ACTIVE_SPEAKER_COLOUR} !important`,
        borderRadius: theme.spacing(2),
    },
    avatar: {
        left: '50%',
        margin: 0,
        position: 'absolute',
        transform: 'translateX(-50%)',
    },
    nameTagWrapper: {
        alignItems: 'center',
        alignSelf: 'end',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        zIndex: 1,
    },
    nameTag: {
        background: 'none',
        color: InQStyleGuide.white,
    },
    nameLeftAligned: {
        justifyContent: 'flex-start',
    },
    fullTag: {
        width: '100%',
        borderRadius: 'none',
        margin: 0,
    },
}));
function NameTag({ meeting, participant, fullWidth, }) {
    const classes = useStyles();
    const languagePack = useLocale();
    return (_jsxs(Box, Object.assign({ className: clsx(classes.nameTagWrapper, {
            [classes.fullTag]: fullWidth,
        }) }, { children: [_jsx(DyteAudioVisualizer, { participant: participant }), _jsx(DyteNameTag, { participant: participant, meeting: meeting, className: classes.nameTag, t: languagePack }), _jsx(DyteNetworkIndicator, { meeting: meeting, participant: participant })] })));
}
export default function ParticipantTile({ meeting, participant, className, fullWidthName, namePosition, handRaised, hideHandRaisedIcon = false, }) {
    const languagePack = useLocale();
    const classes = useStyles();
    const isSpeaking = useIsSpeaking(participant);
    return (_jsxs(Fragment, { children: [_jsxs(DyteParticipantTile, Object.assign({ meeting: meeting, participant: participant, t: languagePack, className: clsx({
                    [classes.handRaised]: handRaised,
                    [classes.speakerTile]: isSpeaking,
                    [classes.nameLeftAligned]: namePosition === NameTagPosition.LEFT,
                }, classes.tile, className) }, { children: [_jsx(DyteAvatar, { className: classes.avatar, participant: participant }), _jsx(NameTag, { meeting: meeting, participant: participant, fullWidth: fullWidthName })] })), _jsx(HandRaisedIndicator, { handRaised: handRaised, hideIcon: hideHandRaisedIcon })] }, participant.id));
}
