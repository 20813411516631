import { useCallback, useState } from 'react';
import { ParticipantsViews } from '../components';
import { useMutationObserver } from './useMutationObserver';
export function useParticipantTiles(participantsView) {
    const [panel] = useState(() => document.querySelector('.clinician-root .meeting-panel'));
    const [participantsRoot, setParticipantsRoot] = useState();
    const [participantsShadowRoot, setParticipantsShadowRoot] = useState();
    const [participantTiles, setParticipantTiles] = useState();
    const [observerParams] = useState({
        childList: true,
        subtree: true,
    });
    // Observe the panel in order to check when the grid is ready
    const panelObserver = useCallback((elem, observer) => {
        var _a;
        if (participantsView === ParticipantsViews.GRID) {
            const root = (_a = elem.querySelector('dyte-grid')) === null || _a === void 0 ? void 0 : _a.shadowRoot;
            if (root) {
                setParticipantsShadowRoot(root);
                observer.disconnect();
            }
        }
        else {
            setParticipantsRoot(elem);
            observer.disconnect();
        }
    }, [participantsView]);
    useMutationObserver(panel, panelObserver, observerParams);
    // If we have a grid, we need to observe a potential shadow roor
    const shadowRootObserver = useCallback((elem) => {
        var _a;
        const root = (_a = elem === null || elem === void 0 ? void 0 : elem.firstElementChild) === null || _a === void 0 ? void 0 : _a.shadowRoot;
        setParticipantsRoot(root);
    }, []);
    useMutationObserver(participantsShadowRoot, shadowRootObserver, observerParams);
    // Now, we can observe for tile changes
    const tilesObserver = useCallback((root) => {
        var _a;
        const tiles = root.querySelectorAll('dyte-participant-tile');
        // In case clinician is sharing screend, DOM will change and we need to re-observe
        if (tiles.length === 0) {
            const newTiles = (_a = root.querySelector('dyte-simple-grid')) === null || _a === void 0 ? void 0 : _a.shadowRoot;
            if (newTiles) {
                setParticipantsRoot(newTiles);
                return;
            }
            return;
        }
        setParticipantTiles(tiles);
    }, []);
    useMutationObserver(participantsRoot, tilesObserver, observerParams);
    return participantTiles;
}
