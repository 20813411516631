var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteDialog, DyteDialogManager, DyteNotifications, DyteParticipants, DyteParticipantsAudio, } from '@dytesdk/react-ui-kit';
import { DyteProvider, useDyteMeeting, useDyteSelector, } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import InQStyleGuide from '../../constants';
import { MeetingStoreProvider, useMeetingStore } from '../../context';
import { useAudioVideo, useBroadcastHandRaised, useIconPack, useLocale, useMeetingParticipants, useNoiseCancellation, useParticipantTiles, useRoomEvents, useToggleMuteParticipants, } from '../../hooks';
import { DeviceOrientationProvider, useDeviceOrientation, } from '../DeviceOrientationProvider';
import { MeetingControls } from '../MeetingControls/MeetingControls';
import { MeetingView } from '../MeetingView/MeetingView';
import SettingsDialog from '../Settings/SettingsDialog';
import SetupScreen from '../SetupScreen';
const useStyles = makeStyles((theme) => ({
    meetingCallContainer: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.75),
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    dyteParticipants: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        height: theme.spacing(40),
        maxWidth: theme.spacing(40),
        top: theme.spacing(9),
        paddingTop: theme.spacing(6),
    },
}));
export var MeetingUserType;
(function (MeetingUserType) {
    MeetingUserType["CLINICIAN"] = "clinician";
    MeetingUserType["PATRON"] = "patron";
})(MeetingUserType || (MeetingUserType = {}));
export var ParticipantsViews;
(function (ParticipantsViews) {
    ParticipantsViews["GRID"] = "grid";
    ParticipantsViews["SPEAKER"] = "speaker";
})(ParticipantsViews || (ParticipantsViews = {}));
const CustomDyteMeeting = ({ onJoinCanceled, onRoomJoined, onRoomLeft, onChatToggle, participantCallbacks, hasUnreadMessages, userType, hideLeave, }) => {
    const { isCameraOn, isMicOn, participantsView, setHideParticipantsList, showParticipantsList, } = useMeetingStore();
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const roomJoined = useDyteSelector((m) => m.self.roomJoined);
    const { setClientRef } = useDeviceOrientation();
    const participantTiles = useParticipantTiles(participantsView);
    useToggleMuteParticipants(participantTiles);
    // We do not need the participants, we just need to invoke then callbacks when needed
    useMeetingParticipants(meeting, participantCallbacks);
    useAudioVideo(meeting, isMicOn, isCameraOn);
    useNoiseCancellation(meeting);
    // Text and color customization
    const languagePack = useLocale();
    const customIconPack = useIconPack();
    useRoomEvents(onRoomJoined, onRoomLeft);
    // Broadcast hand raised status
    useBroadcastHandRaised();
    if (!roomJoined) {
        return (_jsxs(Box, Object.assign({ className: classes.meetingCallContainer }, { children: [_jsx(SetupScreen, { onCancelJoin: onJoinCanceled }), _jsx(DyteDialogManager, { meeting: meeting, t: languagePack })] })));
    }
    return (_jsxs("div", Object.assign({ ref: setClientRef, className: clsx(classes.meetingCallContainer) }, { children: [_jsx(DyteParticipantsAudio, { meeting: meeting }), _jsx(DyteNotifications, { meeting: meeting, config: {
                    config: {
                        notifications: {
                            participant_joined: true,
                            participant_left: true,
                        },
                        notification_sounds: {
                            participant_joined: true,
                            participant_left: true,
                        },
                    },
                }, t: languagePack }), _jsx(MeetingView, { userType: userType }), _jsx(MeetingControls, { hasUnreadMessages: hasUnreadMessages, hideLeave: hideLeave, onChatToggle: onChatToggle, userType: userType }), _jsx(DyteDialog, Object.assign({ open: showParticipantsList, onDyteDialogClose: setHideParticipantsList }, { children: _jsx(DyteParticipants, { className: classes.dyteParticipants, meeting: meeting, t: languagePack, iconPack: customIconPack }) })), _jsx(SettingsDialog, {}), _jsx(DyteDialogManager, { meeting: meeting, t: languagePack, iconPack: customIconPack })] })));
};
export const MeetingCall = (_a) => {
    var { meeting } = _a, props = __rest(_a, ["meeting"]);
    return (_jsx(DeviceOrientationProvider, { children: _jsx(DyteProvider, Object.assign({ value: meeting }, { children: _jsx(MeetingStoreProvider, { children: _jsx(CustomDyteMeeting, Object.assign({}, props)) }) })) }));
};
