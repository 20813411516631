import { IconButton, SvgIcon } from '@material-ui/core';
import _ from 'lodash';
import { useChatContext } from '../../../Contexts/ChatContext';
import {
  useMaybePromise,
  useMessageNotification,
  useObservable,
} from '../../../Hooks';
import { ChatView } from '../../../Services/Chat';
import { ReactComponent as ChatClosed } from '@assets/chat-closed.svg';
import { ReactComponent as ChatOpen } from '@assets/chat-open.svg';
import { ReactComponent as Unread } from '@assets/chat_message_waiting.svg';
import { useExtendedTranslation } from '@services/i18nService';

function MessagingIndicator({ chat }: { chat?: Promise<ChatView> }) {
  const t = useExtendedTranslation();
  const chatUnwrapped = useMaybePromise(chat);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );
  const { showMessaging, updateShowMessaging } = useChatContext();

  useMessageNotification(chat);

  let icon;
  if (!showMessaging && hasUnreadMessages) {
    icon = <SvgIcon component={Unread} />;
  } else if (showMessaging) {
    icon = <SvgIcon component={ChatOpen} />;
  } else {
    icon = <SvgIcon component={ChatClosed} />;
  }

  return (
    <IconButton
      onClick={() => updateShowMessaging(true)}
      disabled={!!showMessaging}
      aria-label={
        !showMessaging && hasUnreadMessages
          ? t('chat.button.unread')
          : t('chat.button')
      }
    >
      {icon}
    </IconButton>
  );
}

export default MessagingIndicator;
