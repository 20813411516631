import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { MeetingUserType, ParticipantsViews, useDeviceOrientation } from '..';
import { useMeetingStore } from '../../context';
import GalleryView from '../ParticipantViews/GalleryView';
import GridView from '../ParticipantViews/GridView';
import MainParticipantsView from '../ParticipantViews/MainParticipantsView';
import { useRoomConfig } from '../../hooks';
const useStyles = makeStyles((theme) => ({
    tilesContainer: {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        marginBottom: theme.spacing(9.5),
        border: '3px solid transparent',
    },
    portraitTilesContainer: {
        marginBottom: 0,
    },
}));
export function MeetingView({ userType }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const { isMobile, participantsView, isImmersiveMode } = useMeetingStore();
    const { isPortrait } = useDeviceOrientation();
    const { roomConfig } = useRoomConfig(meeting.meta.meetingId, meeting.self.id);
    return (_jsxs(Box, Object.assign({ className: clsx(classes.tilesContainer, {
            [classes.portraitTilesContainer]: isPortrait || isMobile,
        }) }, { children: [userType === MeetingUserType.PATRON && !isImmersiveMode && (_jsx(MainParticipantsView, { isPortrait: isPortrait, isMobile: isMobile, handRaised: roomConfig.isHandRaised, handsRaised: roomConfig.handsRaised })), userType === MeetingUserType.CLINICIAN &&
                participantsView === ParticipantsViews.GRID && _jsx(GridView, {}), userType === MeetingUserType.CLINICIAN &&
                participantsView === ParticipantsViews.SPEAKER && _jsx(GalleryView, {})] })));
}
