var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useMobileCameraSelection } from './useFrontBackCameraSelection';
export function useMediaDevices() {
    const { meeting } = useDyteMeeting();
    const [videoDevices, setVideoDevices] = useImmer([]);
    const [currentDevices, setCurrentDevices] = useImmer({});
    const setVideoDevice = useCallback((deviceId) => __awaiter(this, void 0, void 0, function* () {
        const device = videoDevices.find((d) => d.deviceId === deviceId);
        setCurrentDevices((oldDevices) => {
            return Object.assign(Object.assign({}, oldDevices), { video: device });
        });
        if (device != null && (meeting === null || meeting === void 0 ? void 0 : meeting.self)) {
            try {
                yield meeting.self.setDevice(device);
            }
            catch (error) {
                console.error('Error occurred on device selection: ', error);
            }
        }
    }), [meeting === null || meeting === void 0 ? void 0 : meeting.self, setCurrentDevices, videoDevices]);
    const { switchCamera, canSwitchCamera } = useMobileCameraSelection(videoDevices, currentDevices, setVideoDevice);
    useEffect(() => {
        if (!meeting) {
            return;
        }
        const deviceListUpdateCallback = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const devices = yield meeting.self.getVideoDevices();
                setVideoDevices(devices);
            }
            catch (error) {
                console.error('Error occurred on device list update: ', error);
            }
        });
        meeting.self.on('deviceListUpdate', deviceListUpdateCallback);
        // populate first time values
        deviceListUpdateCallback();
        setCurrentDevices({
            video: meeting.self.getCurrentDevices().video,
        });
        return () => {
            meeting.self.off('deviceListUpdate', deviceListUpdateCallback);
        };
    }, [meeting, meeting.self, setCurrentDevices, setVideoDevices]);
    const returnDevices = useMemo(() => {
        return {
            canSwitchCamera,
            currentDevices,
            switchCamera,
            videoDevices,
        };
    }, [canSwitchCamera, currentDevices, switchCamera, videoDevices]);
    return returnDevices;
}
