var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { isBackCamera, isFrontCamera } from '../utils';
export function useMobileCameraSelection(videoDevices, currentDevices, setVideoDevice) {
    var _a;
    const [frontCamera, setFrontCamera] = useImmer(undefined);
    const [backCamera, setBackCamera] = useImmer(undefined);
    useEffect(() => {
        if (videoDevices.length > 1 && !frontCamera && !backCamera) {
            videoDevices.forEach((device) => {
                if (isBackCamera(device)) {
                    setBackCamera(device);
                }
                else if (isFrontCamera(device)) {
                    setFrontCamera(device);
                }
            });
        }
    }, [videoDevices, frontCamera, backCamera, setBackCamera, setFrontCamera]);
    const canSwitchCamera = useMemo(() => Boolean(frontCamera && backCamera), [backCamera, frontCamera]);
    const switchCamera = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _b, _c;
        if (frontCamera && backCamera) {
            try {
                if (!((_b = currentDevices === null || currentDevices === void 0 ? void 0 : currentDevices.video) === null || _b === void 0 ? void 0 : _b.deviceId) ||
                    ((_c = currentDevices === null || currentDevices === void 0 ? void 0 : currentDevices.video) === null || _c === void 0 ? void 0 : _c.deviceId) === backCamera.deviceId) {
                    setVideoDevice(frontCamera.deviceId);
                }
                else {
                    setVideoDevice(backCamera.deviceId);
                }
            }
            catch (error) {
                console.error('Error occurred on camera toggle', error);
            }
        }
    }), [
        backCamera,
        (_a = currentDevices === null || currentDevices === void 0 ? void 0 : currentDevices.video) === null || _a === void 0 ? void 0 : _a.deviceId,
        frontCamera,
        setVideoDevice,
    ]);
    return { canSwitchCamera, switchCamera };
}
