import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';
// Decides if a DyteClient is the host of the meeting based on the first character in the customParticipantId. (H means host)
export const isHost = (participant) => {
    var _a;
    return ((_a = participant.customParticipantId) === null || _a === void 0 ? void 0 : _a[0]) === 'H';
};
const MAX_RETRIES = 5;
const RETRY_DELAY = 2500;
// Returns (first) host participant, active speaker, and last speaker who is not the host/active one
export function useMainSpeakers() {
    const { meeting } = useDyteMeeting();
    const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
    const participantCount = useDyteSelector((m) => m.participants.joined.size + (m.self.roomJoined ? 1 : 0));
    const [host, setHost] = useImmer(undefined);
    const [thirdSpeaker, setThirdSpeaker] = useImmer(undefined);
    const [retryCount, setRetryCount] = useImmer(0);
    const handleActiveSpeaker = useCallback((participant) => {
        const { peerId } = participant;
        const activeSpeaker = joinedParticipants.find((p) => p.id === peerId);
        if (participantCount < 3) {
            return;
        }
        if (!activeSpeaker || activeSpeaker.id === (thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id)) {
            return;
        }
        if (activeSpeaker.id !== (host === null || host === void 0 ? void 0 : host.id) &&
            activeSpeaker.id !== meeting.self.id) {
            setThirdSpeaker(activeSpeaker);
        }
    }, [
        host === null || host === void 0 ? void 0 : host.id,
        joinedParticipants,
        meeting.self.id,
        participantCount,
        setThirdSpeaker,
        thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id,
    ]);
    // initialize host for patrons
    useEffect(() => {
        const findHost = () => {
            const initialHost = joinedParticipants.find(isHost);
            if (initialHost) {
                setHost(initialHost);
            }
            else if (isHost(meeting.self)) {
                setHost(meeting.self);
            }
            else if (retryCount < MAX_RETRIES) {
                setRetryCount((count) => count + 1);
                setTimeout(findHost, RETRY_DELAY);
            }
            else {
                console.error('No host found after maximum retries');
            }
        };
        findHost();
    }, [joinedParticipants, meeting.self, setHost, retryCount, setRetryCount]);
    useEffect(() => {
        meeting.participants.on('activeSpeaker', handleActiveSpeaker);
        return () => {
            meeting.participants.off('activeSpeaker', handleActiveSpeaker);
        };
    }, [
        handleActiveSpeaker,
        joinedParticipants,
        meeting.participants,
        setThirdSpeaker,
    ]);
    useEffect(() => {
        if (!thirdSpeaker && participantCount === 3) {
            setThirdSpeaker(joinedParticipants.at(1));
        }
    }, [joinedParticipants, thirdSpeaker, participantCount, setThirdSpeaker]);
    useEffect(() => {
        const participantJoined = (participant) => {
            // Set the host if there is no host and the joining participant is a host
            if (!host && isHost(participant)) {
                setHost(participant);
            }
        };
        const participantLeft = (participant) => {
            let newHost = joinedParticipants.find(isHost);
            if (isHost(participant)) {
                if (!host) {
                    // Prioritize setting newHost to meeting.self if it's a host and newHost is not already set
                    if (!newHost && isHost(meeting.self)) {
                        newHost = meeting.self;
                    }
                    // If still no newHost, default to the current participant
                    if (!newHost) {
                        newHost = participant;
                    }
                    // Finally, set the host
                    setHost(newHost);
                }
            }
        };
        // Set up event listeners, to monitor participant actions
        meeting.participants.joined.on('participantJoined', participantJoined);
        meeting.participants.joined.on('participantLeft', participantLeft);
        // Remove event listeners on unmount
        return () => {
            meeting.participants.joined.off('participantJoined', participantJoined);
            meeting.participants.joined.off('participantLeft', participantLeft);
        };
        // To be run only once to set up listeners
    }, [
        host,
        joinedParticipants,
        meeting.participants.joined,
        meeting.self,
        setHost,
    ]);
    return [host, thirdSpeaker];
}
