import { useCallback, useEffect, useRef, useState } from 'react';
import hark from 'hark';
// Uses Hark module to listen to the participant's audio stream to detect whether it is speaking or not
export function useIsSpeaking(participant) {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const harkInstanceRef = useRef(null);
    const speakingOnListener = useCallback(() => {
        setIsSpeaking(true);
    }, []);
    const speakingOffListener = useCallback(() => {
        setIsSpeaking(false);
    }, []);
    const cleanupHarkInstance = useCallback(() => {
        if (harkInstanceRef.current) {
            harkInstanceRef.current.off('speaking', speakingOnListener);
            harkInstanceRef.current.off('stopped_speaking', speakingOffListener);
            harkInstanceRef.current.stop();
            harkInstanceRef.current = null;
        }
    }, [speakingOnListener, speakingOffListener]);
    useEffect(() => {
        if (!participant) {
            return;
        }
        const audioUpdateListener = ({ audioEnabled, audioTrack, }) => {
            cleanupHarkInstance();
            if (audioEnabled && audioTrack != null) {
                const stream = new MediaStream();
                stream.addTrack(audioTrack);
                harkInstanceRef.current = hark(stream);
                harkInstanceRef.current.on('speaking', speakingOnListener);
                harkInstanceRef.current.on('stopped_speaking', speakingOffListener);
            }
            else {
                setIsSpeaking(false);
            }
        };
        audioUpdateListener(participant); // initialization
        // Listening to mute/unmute
        participant.addListener('audioUpdate', audioUpdateListener);
        return () => {
            participant === null || participant === void 0 ? void 0 : participant.removeListener('audioUpdate', audioUpdateListener);
            cleanupHarkInstance();
        };
    }, [
        cleanupHarkInstance,
        participant,
        speakingOffListener,
        speakingOnListener,
    ]);
    return isSpeaking;
}
