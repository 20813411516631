import DyteKrispNoiseCancellation from '@dytesdk/krisp-noise-cancellation';
import { useEffect } from 'react';
import { logger } from '../utils/logger';
export function useNoiseCancellation(meeting) {
    useEffect(() => {
        const krisp = new DyteKrispNoiseCancellation();
        // Initialise the middleware
        krisp
            .init(meeting)
            .then(() => {
            krisp
                .activate()
                .catch((e) => {
                logger(e, 'Error while activating krisp', {
                    level: 'error',
                });
            });
        })
            .catch((e) => {
            logger(e, 'Error while creating krisp middleware:', {
                level: 'error',
            });
        });
        return () => {
            krisp
                .deactivate()
                .catch((e) => {
                logger(e, 'Error while deactivating krisp', {
                    level: 'error',
                });
            });
        };
    }, [meeting]);
}
