var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteAudioVisualizer, DyteAvatar, DyteButton, DyteCameraToggle, DyteMicToggle, DyteNameTag, DyteParticipantTile, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useMeetingStore } from '../context';
import SettingsButton from './Settings/SettingsButton';
import SettingsDialog from './Settings/SettingsDialog';
import { useIconPack, useLocale } from '../hooks';
const useStyles = makeStyles((theme) => ({
    setupContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        padding: theme.spacing(3),
        maxWidth: '100%',
        overflowY: 'auto',
    },
    participantTile: {
        '.clinician-root &': {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'unset',
            width: theme.spacing(35),
        },
    },
    avatar: {
        position: 'absolute',
    },
    controlsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
    },
    controlsRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    controlItem: {
        marginRight: theme.spacing(1),
    },
    text: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    name: {
        fontSize: '1.75rem',
        marginBottom: theme.spacing(1.5),
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem',
        },
    },
    cancelButton: {
        marginTop: theme.spacing(2),
    },
}));
export default function SetupScreen({ onCancelJoin }) {
    const { setShowSettings, toggleCamera, toggleMic } = useMeetingStore();
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const customIconPack = useIconPack();
    const [joining, setJoining] = useState(false);
    const onJoin = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!meeting || joining) {
            return;
        }
        try {
            setJoining(true);
            yield meeting.join();
        }
        catch (e) {
            console.log('Error trying to join meeting', e);
        }
    }), [meeting, joining]);
    const onCancel = useCallback(() => {
        if (joining) {
            return;
        }
        if (onCancelJoin) {
            onCancelJoin();
        }
    }, [onCancelJoin, joining]);
    return (_jsxs(Box, Object.assign({ className: classes.setupContainer }, { children: [_jsx(Box, { children: _jsxs(DyteParticipantTile, Object.assign({ meeting: meeting, participant: meeting.self, className: classes.participantTile }, { children: [_jsx(DyteAvatar, { className: classes.avatar, participant: meeting.self }), _jsx(DyteNameTag, Object.assign({ participant: meeting.self, t: languagePack }, { children: _jsx(DyteAudioVisualizer, { participant: meeting.self, slot: "start" }) })), _jsxs(Box, Object.assign({ className: classes.controlsContainer }, { children: [_jsx(Box, Object.assign({ className: classes.controlsRow }, { children: _jsx(SettingsButton, { onClick: setShowSettings }) })), _jsxs(Box, Object.assign({ className: classes.controlsRow }, { children: [_jsx(DyteMicToggle, { className: classes.controlItem, meeting: meeting, size: "sm", t: languagePack, iconPack: customIconPack, onClick: toggleMic }), _jsx(DyteCameraToggle, { meeting: meeting, size: "sm", t: languagePack, iconPack: customIconPack, onClick: toggleCamera })] }))] }))] })) }), _jsxs(Box, { children: [_jsx(Typography, Object.assign({ className: classes.text }, { children: languagePack('setup_screen.joining_as') })), _jsx(Typography, Object.assign({ className: classes.name }, { children: meeting.self.name })), _jsx(DyteButton, Object.assign({ id: "confirm-join-button", kind: "wide", size: "lg", onClick: onJoin }, (joining && { disabled: true }), { children: languagePack(joining ? 'connecting' : 'join') })), onCancelJoin && (_jsx(DyteButton, Object.assign({ id: "cancel-join-button", kind: "wide", size: "lg", className: classes.cancelButton, variant: "danger", onClick: onCancel }, (joining && { disabled: true }), { children: languagePack('cancel') })))] }), _jsx(SettingsDialog, {})] })));
}
