import { useEffect } from 'react';
// Turns on/off camera and microphone on meeting initialization
export function useAudioVideo(meeting, isMicOn, isCameraOn) {
    useEffect(() => {
        if (meeting) {
            if (isMicOn) {
                meeting.self.enableAudio();
            }
            else {
                meeting.self.disableAudio();
            }
            if (isCameraOn) {
                meeting.self.enableVideo();
            }
            else {
                meeting.self.disableVideo();
            }
        }
        // Call it once, on meeting init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting]);
}
