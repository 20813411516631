import { useMemo, useEffect } from 'react';
import { raam } from 'raam-client-lib';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { DEFAULT_CAPTION_LANGUAGE_CODE } from '../constants';
const initRoomConfig = {
    meetingName: '',
    notificationId: '',
    roomLanguage: DEFAULT_CAPTION_LANGUAGE_CODE,
    participantsCaptioning: [],
    handsRaised: [],
    isCaptioningEnabled: false,
    isHandRaised: false,
};
export function useRoomConfig(meetingMetaId, meetingSelfId) {
    const [roomConfig, setRoomConfig] = useImmer(initRoomConfig);
    const [hasLoadedInitially, setHasLoadedInitially] = useImmer(false);
    const isCaptioningEnabled = useMemo(() => {
        if (!meetingSelfId) {
            return false;
        }
        return _.includes(roomConfig.participantsCaptioning, meetingSelfId);
    }, [meetingSelfId, roomConfig.participantsCaptioning]);
    const isHandRaised = useMemo(() => {
        if (!meetingSelfId) {
            return false;
        }
        return _.includes(roomConfig.handsRaised, meetingSelfId);
    }, [meetingSelfId, roomConfig.handsRaised]);
    const rc = useMemo(() => (Object.assign(Object.assign({}, roomConfig), { isCaptioningEnabled,
        isHandRaised })), [isCaptioningEnabled, isHandRaised, roomConfig]);
    useEffect(() => {
        const id = meetingMetaId || meetingSelfId;
        if (!id) {
            return;
        }
        const o$ = raam.meetingRoom.observeRoomConfig(id);
        const subscription = o$.subscribe((v) => {
            setRoomConfig(v.properties);
            if (!hasLoadedInitially) {
                setHasLoadedInitially(true);
            }
        }, (e) => {
            console.error('Error fetching room config', e);
            setHasLoadedInitially(true);
        });
        return () => subscription.unsubscribe();
    }, [
        hasLoadedInitially,
        meetingMetaId,
        meetingSelfId,
        setHasLoadedInitially,
        setRoomConfig,
    ]);
    return { roomConfig: rc, hasLoadedInitially };
}
