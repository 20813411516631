import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { raam } from 'raam-client-lib';

import { ReactComponent as Cheveron } from '@assets/DownCheveron.svg';

import { SinglePanelLayout } from '@components';

import InQStyleGuide from '@constants';

import useClinicConfig from '@hooks/useClinicConfig';

import { getLanguage, useExtendedTranslation } from '@services/i18nService';

type ISubOptions = {
  id: string;
  name: {
    en: string;
    fr: string;
  };
};

type IMeetingOption = {
  value: string;
  label: string;
  subOptions?: ISubOptions[];
};

const defaultOptions: IMeetingOption[] = [
  {
    value: 'CLINIC_MEETING',
    label: 'meetingselect.clinic',
  },
  {
    value: 'COMMUNITY_MEETING',
    label: 'meetingselect.community',
  },
  {
    value: 'UNKNOWN',
    label: 'meetingselect.unknown',
  },
];

const useStyles = makeStyles({
  header: {
    marginBottom: '1em',
  },
  footer: {
    fontStyle: 'italic',
    color: `${InQStyleGuide.blue.medium}`,
  },
  mainGrid: {
    height: '100%',
  },
  subOptionsControl: {
    minWidth: '95%',
    marginLeft: '1.5rem',
  },

  select: {
    backgroundColor: 'white',
  },
});

export function ScreenMeetingConfirmation({
  message_key,
  onNext,
  unknownDefault,
}: {
  message_key: string;
  onNext?: (data: object) => void;
  unknownDefault: boolean;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const [selectedMeetingName, setSelectedMeetingName] = useState('');
  const [selectedMeetingType, setSelectedMeetingType] = useState(
    unknownDefault ? 'UNKNOWN' : ''
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !unknownDefault
  );
  const lang = getLanguage();

  const [meetingOptions, setMeetingOptions] = useState<
    IMeetingOption[] | undefined
  >();

  const classes = useStyles();

  useEffect(() => {
    const setOptionsIfEmpty = (options: IMeetingOption[]) =>
      setMeetingOptions((previousOptions) => previousOptions || options);

    const timeout = setTimeout(() => {
      setOptionsIfEmpty(defaultOptions);
    }, 10000);

    raam.patron
      .getTodaysMeetingNotEnded()
      .then((options: IMeetingOption[]) => {
        setOptionsIfEmpty(options);
      })
      .catch((err: any) => {
        setOptionsIfEmpty(defaultOptions);
        console.error(
          "Something went wrong while fetching today's meeting",
          err
        );
      })
      .finally(() => timeout && clearTimeout(timeout));

    return () => timeout && clearTimeout(timeout);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsButtonDisabled(false);
      setSelectedMeetingType((event.target as HTMLInputElement).value);
      setSelectedMeetingName('');
    },
    []
  );

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMeetingName(event.target.value as string);
  };
  const handleOnNext = useCallback(() => {
    if (onNext) {
      onNext({
        selectedMeetingType,
        selectedMeetingName,
      });
    }
  }, [onNext, selectedMeetingType, selectedMeetingName]);

  const handleClick = () => {
    setIsButtonDisabled(true);
    handleOnNext();
  };

  return (
    <Box display="flex" flex={1}>
      <SinglePanelLayout
        title={
          <Typography
            variant="h5"
            color="primary"
            align="center"
            className={classes.header}
          >
            {t(message_key)}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                className={classes.footer}
              >
                {t('serviceDisclaimer')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={isButtonDisabled}
              >
                {t('checkin.continue.button')}
              </Button>
            </Grid>
          </Grid>
        }
        logo={clinicConfig.logoName}
      >
        <Container maxWidth="xs">
          {!meetingOptions ? (
            <Grid
              container
              direction="column"
              spacing={1}
              className={classes.mainGrid}
              justifyContent="center"
            >
              <Grid container item justifyContent="center">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              spacing={1}
              className={classes.mainGrid}
            >
              {meetingOptions.map((option) => {
                return (
                  <Fragment key={option.value}>
                    <Grid item>
                      <FormControlLabel
                        value={option.value}
                        control={
                          <Radio
                            checked={selectedMeetingType === option.value}
                            onChange={handleChange}
                          />
                        }
                        label={t(option.label)}
                      />
                    </Grid>
                    {selectedMeetingType === option.value &&
                      option.subOptions &&
                      option.subOptions.length !== 0 && (
                        <Grid item>
                          <FormControl
                            variant="outlined"
                            className={classes.subOptionsControl}
                          >
                            <InputLabel shrink={false}>
                              {!selectedMeetingName &&
                                t('checkin.meeting.placeholder')}
                            </InputLabel>
                            <Select
                              value={selectedMeetingName}
                              onChange={handleSelectChange}
                              defaultValue={_.head(option.subOptions)}
                              variant="outlined"
                              IconComponent={Cheveron}
                              className={classes.select}
                            >
                              {option.subOptions.map((data) => (
                                <MenuItem
                                  key={data.name[lang]}
                                  value={data.name[lang]}
                                >
                                  {data.name[lang]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                  </Fragment>
                );
              })}
            </Grid>
          )}
        </Container>
      </SinglePanelLayout>
    </Box>
  );
}
