import { createContext, useContext, useState } from 'react';

interface IChatContext {
  showMessaging: boolean;
  updateShowMessaging: (nextShowMessaging: boolean) => void;
  lastNotifiedMessageId: string;
  updateLastNotifiedMessageId: (id: string) => void;
}

const ChatContext = createContext<IChatContext>({
  showMessaging: false,
  updateShowMessaging: () => {},
  lastNotifiedMessageId: '',
  updateLastNotifiedMessageId: () => {},
});

export const ChatProvider: React.FC = ({ children }) => {
  const [showMessaging, setShowMessaging] = useState(false);
  const [lastNotifiedMessageId, setLastNotifiedMessageId] = useState('');

  return (
    <ChatContext.Provider
      value={{
        showMessaging,
        updateShowMessaging: setShowMessaging,
        lastNotifiedMessageId,
        updateLastNotifiedMessageId: setLastNotifiedMessageId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export function useChatContext() {
  return useContext(ChatContext);
}
