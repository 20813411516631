import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ParticipantTile, { NameTagPosition } from '../shared/ParticipantTile';
import { useMainSpeakers } from '../../hooks/useMainSpeakers';
import InQStyleGuide from '../../constants';
import GridView from './GridView';
const useStyles = makeStyles((theme) => ({
    viewContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    portraitViewContainer: {
        flexDirection: 'column',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    landscapeViewContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    participantsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        gap: theme.spacing(2),
    },
    portraitParticipantsContainer: {
        flexDirection: 'column',
    },
    tileWrapper: {
        position: 'relative',
        background: InQStyleGuide.grey.light,
        borderRadius: theme.spacing(2),
        flex: 1,
    },
    participantTile: {
        aspectRatio: 'unset',
        height: '100%',
    },
}));
export default function MainParticipantsView({ isPortrait, isMobile, handRaised, handsRaised, }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const [hostSpeaker, thirdSpeaker] = useMainSpeakers();
    const nameTagPosition = isPortrait || isMobile ? NameTagPosition.LEFT : NameTagPosition.CENTER;
    const isHostHandRaised = useMemo(() => (hostSpeaker && handsRaised.includes(hostSpeaker.id)) || false, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handsRaised, hostSpeaker, hostSpeaker === null || hostSpeaker === void 0 ? void 0 : hostSpeaker.id]);
    const isThirdSpeakerHandRaised = useMemo(() => (thirdSpeaker && handsRaised.includes(thirdSpeaker.id)) || false, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handsRaised, thirdSpeaker, thirdSpeaker === null || thirdSpeaker === void 0 ? void 0 : thirdSpeaker.id]);
    const sharingScreen = useDyteSelector((m) => m.participants.joined.toArray().find((p) => p.screenShareEnabled));
    if (!isMobile || sharingScreen) {
        return (_jsx(Box, Object.assign({ className: clsx(classes.viewContainer, classes.portraitViewContainer) }, { children: _jsx(GridView, {}) })));
    }
    return (_jsx(Box, Object.assign({ className: clsx(classes.viewContainer, {
            [classes.portraitViewContainer]: isPortrait,
            [classes.landscapeViewContainer]: !isPortrait,
        }) }, { children: _jsxs(Box, Object.assign({ className: clsx(classes.participantsContainer, {
                [classes.portraitParticipantsContainer]: isPortrait,
            }) }, { children: [_jsx(Box, Object.assign({ className: classes.tileWrapper }, { children: _jsx(ParticipantTile, { participant: meeting.self, meeting: meeting, className: classes.participantTile, namePosition: nameTagPosition, handRaised: handRaised, hideHandRaisedIcon: true }, meeting.self.id) })), hostSpeaker && meeting.self.id !== hostSpeaker.id && (_jsx(Box, Object.assign({ className: classes.tileWrapper }, { children: _jsx(ParticipantTile, { participant: hostSpeaker, meeting: meeting, className: classes.participantTile, namePosition: nameTagPosition, handRaised: isHostHandRaised }, hostSpeaker.id) }))), thirdSpeaker && (_jsx(Box, Object.assign({ className: clsx(classes.tileWrapper) }, { children: _jsx(ParticipantTile, { participant: thirdSpeaker, meeting: meeting, className: classes.participantTile, namePosition: nameTagPosition, handRaised: isThirdSpeakerHandRaised }, thirdSpeaker.id) })))] })) })));
}
