/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { MeetingCall, MeetingUserType } from 'raam-shared';

import { SinglePanelWithMessaging } from '@components';
import MessagingDialog from '@components/Layout/SinglePanel/MessagingDialog/MessagingDialog';

import { useChatContext } from '@contexts/ChatContext';
import MeetingContext, { MeetingViewEnum } from '@contexts/MeetingContext';

import { useMaybePromise, useMessageNotification, useObservable } from '@hooks';

import Connecting from '@screens/Meeting/Connecting';
import Rejoin from '@screens/Meeting/Rejoin';

import { ChatView, createChatView, ParticipantType } from '@services/Chat';
import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

function Meeting({
  patron,
  onContinue,
}: {
  patron: IPatron;
  onContinue: Function;
}) {
  useExtendedTranslation();
  const {
    meeting,
    meetingViewState: currentState,
    setMeetingViewState: setCurrentState,
  } = useContext(MeetingContext);

  const { updateShowMessaging } = useChatContext();

  const [chatPromise, setChatPromise] = useState<Promise<ChatView>>();

  const chatUnwrapped = useMaybePromise(chatPromise);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );

  const onReconnect = useCallback(() => {
    setCurrentState(MeetingViewEnum.InMeeting);
  }, [setCurrentState]);

  // Setting up chat view (for chat dialog)
  useEffect(() => {
    const conversationId = patron?.properties?.conversationId;
    const patronId = patron?.id;
    if (conversationId && patronId) {
      const chat = createChatView(
        conversationId,
        ParticipantType.User,
        patronId
      );
      setChatPromise(chat);
    }
  }, [patron]);

  useMessageNotification(chatPromise);

  useEffect(() => {
    setCurrentState(
      patron.properties.meetingName
        ? MeetingViewEnum.InMeeting
        : MeetingViewEnum.InRejoin
    );
  }, [patron.properties.meetingName, setCurrentState]);

  const roomLeftCb = useCallback(() => {
    setCurrentState(MeetingViewEnum.InRejoin);
  }, [setCurrentState]);

  return (
    <Box className="meeting-panel">
      {currentState === MeetingViewEnum.InMeeting ? (
        meeting ? (
          <>
            <MessagingDialog chat={chatPromise} patron={patron} />
            <MeetingCall
              meeting={meeting}
              onRoomLeft={roomLeftCb}
              onChatToggle={() => updateShowMessaging(true)}
              hasUnreadMessages={hasUnreadMessages}
              userType={MeetingUserType.PATRON}
            />
          </>
        ) : (
          <Connecting />
        )
      ) : (
        <SinglePanelWithMessaging patron={patron}>
          <Rejoin onContinue={onContinue} onRejoin={onReconnect} />
        </SinglePanelWithMessaging>
      )}
    </Box>
  );
}

export default Meeting;
