import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { DyteGrid, DyteGridPagination, defaultConfig, } from '@dytesdk/react-ui-kit';
import { Box } from '@material-ui/core';
import { useLocale } from '../../hooks';
const config = Object.assign({}, defaultConfig);
if (config.root) {
    config.root['dyte-participant-tile'] = config.root['dyte-participant-tile'].children;
}
if (config.config) {
    config.config = Object.assign(Object.assign({}, config.config), { notification_sounds: {
            participant_joined: true,
            participant_left: true,
        }, notifications: {
            participant_joined: true,
            participant_left: true,
        } });
}
export default function GridView() {
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ height: 48, display: "flex", justifyItems: "center" }, { children: _jsx(DyteGridPagination, { meeting: meeting }) })), _jsx(DyteGrid, { meeting: meeting, config: config, t: languagePack })] }));
}
